export const getGlobalStateValue = (value) => {
  if (typeof window !== "undefined") {
    // update latest pageType 
   const pageType = document.querySelector('meta[name="page-type"]')?.getAttribute("content") || "Home Page"
    updateStateSession({ pageType })

    const globalState = JSON.parse(sessionStorage.getItem("globalStore")) || {};

    return globalState[value];
  }
};

export const updateStateSession = (newState) => {
  if (typeof window !== "undefined") {
    const prevState = JSON.parse(sessionStorage.getItem("globalStore"));

    sessionStorage.setItem(
      "globalStore",
      JSON.stringify({ ...prevState, ...newState })
    );
  }
};

const countryDialCodes = {
  AF: { name: "Afghanistan", mobileCode: "93" },
  AX: { name: "Aland Islands", mobileCode: "358" },
  AL: { name: "Albania", mobileCode: "355" },
  DZ: { name: "Algeria", mobileCode: "213" },
  AS: { name: "AmericanSamoa", mobileCode: "1684" },
  AD: { name: "Andorra", mobileCode: "376" },
  AO: { name: "Angola", mobileCode: "244" },
  AI: { name: "Anguilla", mobileCode: "1264" },
  AQ: { name: "Antarctica", mobileCode: "672" },
  AG: { name: "Antigua and Barbuda", mobileCode: "1268" },
  AR: { name: "Argentina", mobileCode: "54" },
  AM: { name: "Armenia", mobileCode: "374" },
  AW: { name: "Aruba", mobileCode: "297" },
  AU: { name: "Australia", mobileCode: "61" },
  AT: { name: "Austria", mobileCode: "43" },
  AZ: { name: "Azerbaijan", mobileCode: "994" },
  BS: { name: "Bahamas", mobileCode: "1242" },
  BH: { name: "Bahrain", mobileCode: "973" },
  BD: { name: "Bangladesh", mobileCode: "880" },
  BB: { name: "Barbados", mobileCode: "1246" },
  BY: { name: "Belarus", mobileCode: "375" },
  BE: { name: "Belgium", mobileCode: "32" },
  BZ: { name: "Belize", mobileCode: "501" },
  BJ: { name: "Benin", mobileCode: "229" },
  BM: { name: "Bermuda", mobileCode: "1441" },
  BT: { name: "Bhutan", mobileCode: "975" },
  BO: { name: "Bolivia, Plurinational State of", mobileCode: "591" },
  BA: { name: "Bosnia and Herzegovina", mobileCode: "387" },
  BW: { name: "Botswana", mobileCode: "267" },
  BR: { name: "Brazil", mobileCode: "55" },
  IO: { name: "British Indian Ocean Territory", mobileCode: "246" },
  BN: { name: "Brunei Darussalam", mobileCode: "673" },
  BG: { name: "Bulgaria", mobileCode: "359" },
  BF: { name: "Burkina Faso", mobileCode: "226" },
  BI: { name: "Burundi", mobileCode: "257" },
  KH: { name: "Cambodia", mobileCode: "855" },
  CM: { name: "Cameroon", mobileCode: "237" },
  CA: { name: "Canada", mobileCode: "1" },
  CV: { name: "Cape Verde", mobileCode: "238" },
  KY: { name: "Cayman Islands", mobileCode: "345" },
  CF: { name: "Central African Republic", mobileCode: "236" },
  TD: { name: "Chad", mobileCode: "235" },
  CL: { name: "Chile", mobileCode: "56" },
  CN: { name: "China", mobileCode: "86" },
  CX: { name: "Christmas Island", mobileCode: "61" },
  CC: { name: "Cocos (Keeling) Islands", mobileCode: "61" },
  CO: { name: "Colombia", mobileCode: "57" },
  KM: { name: "Comoros", mobileCode: "269" },
  CG: { name: "Congo", mobileCode: "242" },
  CD: {
    name: "Congo, The Democratic Republic of the Congo",
    mobileCode: "243",
  },
  CK: { name: "Cook Islands", mobileCode: "682" },
  CR: { name: "Costa Rica", mobileCode: "506" },
  CI: { name: "Cote d'Ivoire", mobileCode: "225" },
  HR: { name: "Croatia", mobileCode: "385" },
  CU: { name: "Cuba", mobileCode: "53" },
  CY: { name: "Cyprus", mobileCode: "357" },
  CZ: { name: "Czech Republic", mobileCode: "420" },
  DK: { name: "Denmark", mobileCode: "45" },
  DJ: { name: "Djibouti", mobileCode: "253" },
  DM: { name: "Dominica", mobileCode: "1767" },
  DO: { name: "Dominican Republic", mobileCode: "1849" },
  EC: { name: "Ecuador", mobileCode: "593" },
  EG: { name: "Egypt", mobileCode: "20" },
  SV: { name: "El Salvador", mobileCode: "503" },
  GQ: { name: "Equatorial Guinea", mobileCode: "240" },
  ER: { name: "Eritrea", mobileCode: "291" },
  EE: { name: "Estonia", mobileCode: "372" },
  ET: { name: "Ethiopia", mobileCode: "251" },
  FK: { name: "Falkland Islands (Malvinas)", mobileCode: "500" },
  FO: { name: "Faroe Islands", mobileCode: "298" },
  FJ: { name: "Fiji", mobileCode: "679" },
  FI: { name: "Finland", mobileCode: "358" },
  FR: { name: "France", mobileCode: "33" },
  GF: { name: "French Guiana", mobileCode: "594" },
  PF: { name: "French Polynesia", mobileCode: "689" },
  GA: { name: "Gabon", mobileCode: "241" },
  GM: { name: "Gambia", mobileCode: "220" },
  GE: { name: "Georgia", mobileCode: "995" },
  DE: { name: "Germany", mobileCode: "49" },
  GH: { name: "Ghana", mobileCode: "233" },
  GI: { name: "Gibraltar", mobileCode: "350" },
  GR: { name: "Greece", mobileCode: "30" },
  GL: { name: "Greenland", mobileCode: "299" },
  GD: { name: "Grenada", mobileCode: "1473" },
  GP: { name: "Guadeloupe", mobileCode: "590" },
  GU: { name: "Guam", mobileCode: "1671" },
  GT: { name: "Guatemala", mobileCode: "502" },
  GG: { name: "Guernsey", mobileCode: "44" },
  GN: { name: "Guinea", mobileCode: "224" },
  GW: { name: "Guinea-Bissau", mobileCode: "245" },
  GY: { name: "Guyana", mobileCode: "595" },
  HT: { name: "Haiti", mobileCode: "509" },
  VA: { name: "Holy See (Vatican City State)", mobileCode: "379" },
  HN: { name: "Honduras", mobileCode: "504" },
  HK: { name: "Hong Kong", mobileCode: "852" },
  HU: { name: "Hungary", mobileCode: "36" },
  IS: { name: "Iceland", mobileCode: "354" },
  IN: { name: "India", mobileCode: "91" },
  ID: { name: "Indonesia", mobileCode: "62" },
  IR: { name: "Iran, Islamic Republic of Persian Gulf", mobileCode: "98" },
  IQ: { name: "Iraq", mobileCode: "964" },
  IE: { name: "Ireland", mobileCode: "353" },
  IM: { name: "Isle of Man", mobileCode: "44" },
  IL: { name: "Israel", mobileCode: "972" },
  IT: { name: "Italy", mobileCode: "39" },
  JM: { name: "Jamaica", mobileCode: "1876" },
  JP: { name: "Japan", mobileCode: "81" },
  JE: { name: "Jersey", mobileCode: "44" },
  JO: { name: "Jordan", mobileCode: "962" },
  KZ: { name: "Kazakhstan", mobileCode: "77" },
  KE: { name: "Kenya", mobileCode: "254" },
  KI: { name: "Kiribati", mobileCode: "686" },
  KP: {
    name: "Korea, Democratic People's Republic of Korea",
    mobileCode: "850",
  },
  KR: { name: "Korea, Republic of South Korea", mobileCode: "82" },
  KW: { name: "Kuwait", mobileCode: "965" },
  KG: { name: "Kyrgyzstan", mobileCode: "996" },
  LA: { name: "Laos", mobileCode: "856" },
  LV: { name: "Latvia", mobileCode: "371" },
  LB: { name: "Lebanon", mobileCode: "961" },
  LS: { name: "Lesotho", mobileCode: "266" },
  LR: { name: "Liberia", mobileCode: "231" },
  LY: { name: "Libyan Arab Jamahiriya", mobileCode: "218" },
  LI: { name: "Liechtenstein", mobileCode: "423" },
  LT: { name: "Lithuania", mobileCode: "370" },
  LU: { name: "Luxembourg", mobileCode: "352" },
  MO: { name: "Macao", mobileCode: "853" },
  MK: { name: "Macedonia", mobileCode: "389" },
  MG: { name: "Madagascar", mobileCode: "261" },
  MW: { name: "Malawi", mobileCode: "265" },
  MY: { name: "Malaysia", mobileCode: "60" },
  MV: { name: "Maldives", mobileCode: "960" },
  ML: { name: "Mali", mobileCode: "223" },
  MT: { name: "Malta", mobileCode: "356" },
  MH: { name: "Marshall Islands", mobileCode: "692" },
  MQ: { name: "Martinique", mobileCode: "596" },
  MR: { name: "Mauritania", mobileCode: "222" },
  MU: { name: "Mauritius", mobileCode: "230" },
  YT: { name: "Mayotte", mobileCode: "262" },
  MX: { name: "Mexico", mobileCode: "52" },
  FM: {
    name: "Micronesia, Federated States of Micronesia",
    mobileCode: "691",
  },
  MD: { name: "Moldova", mobileCode: "373" },
  MC: { name: "Monaco", mobileCode: "377" },
  MN: { name: "Mongolia", mobileCode: "976" },
  ME: { name: "Montenegro", mobileCode: "382" },
  MS: { name: "Montserrat", mobileCode: "1664" },
  MA: { name: "Morocco", mobileCode: "212" },
  MZ: { name: "Mozambique", mobileCode: "258" },
  MM: { name: "Myanmar", mobileCode: "95" },
  NA: { name: "Namibia", mobileCode: "264" },
  NR: { name: "Nauru", mobileCode: "674" },
  NP: { name: "Nepal", mobileCode: "977" },
  NL: { name: "Netherlands", mobileCode: "31" },
  AN: { name: "Netherlands Antilles", mobileCode: "599" },
  NC: { name: "New Caledonia", mobileCode: "687" },
  NZ: { name: "New Zealand", mobileCode: "64" },
  NI: { name: "Nicaragua", mobileCode: "505" },
  NE: { name: "Niger", mobileCode: "227" },
  NG: { name: "Nigeria", mobileCode: "234" },
  NU: { name: "Niue", mobileCode: "683" },
  NF: { name: "Norfolk Island", mobileCode: "672" },
  MP: { name: "Northern Mariana Islands", mobileCode: "1670" },
  NO: { name: "Norway", mobileCode: "47" },
  OM: { name: "Oman", mobileCode: "968" },
  PK: { name: "Pakistan", mobileCode: "92" },
  PW: { name: "Palau", mobileCode: "680" },
  PS: { name: "Palestinian Territory, Occupied", mobileCode: "970" },
  PA: { name: "Panama", mobileCode: "507" },
  PG: { name: "Papua New Guinea", mobileCode: "675" },
  PY: { name: "Paraguay", mobileCode: "595" },
  PE: { name: "Peru", mobileCode: "51" },
  PH: { name: "Philippines", mobileCode: "63" },
  PN: { name: "Pitcairn", mobileCode: "872" },
  PL: { name: "Poland", mobileCode: "48" },
  PT: { name: "Portugal", mobileCode: "351" },
  PR: { name: "Puerto Rico", mobileCode: "1939" },
  QA: { name: "Qatar", mobileCode: "974" },
  RO: { name: "Romania", mobileCode: "40" },
  RU: { name: "Russia", mobileCode: "7" },
  RW: { name: "Rwanda", mobileCode: "250" },
  RE: { name: "Reunion", mobileCode: "262" },
  BL: { name: "Saint Barthelemy", mobileCode: "590" },
  SH: {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    mobileCode: "290",
  },
  KN: { name: "Saint Kitts and Nevis", mobileCode: "1869" },
  LC: { name: "Saint Lucia", mobileCode: "1758" },
  MF: { name: "Saint Martin", mobileCode: "590" },
  PM: { name: "Saint Pierre and Miquelon", mobileCode: "508" },
  VC: { name: "Saint Vincent and the Grenadines", mobileCode: "1784" },
  WS: { name: "Samoa", mobileCode: "685" },
  SM: { name: "San Marino", mobileCode: "378" },
  ST: { name: "Sao Tome and Principe", mobileCode: "239" },
  SA: { name: "Saudi Arabia", mobileCode: "966" },
  SN: { name: "Senegal", mobileCode: "221" },
  RS: { name: "Serbia", mobileCode: "381" },
  SC: { name: "Seychelles", mobileCode: "248" },
  SL: { name: "Sierra Leone", mobileCode: "232" },
  SG: { name: "Singapore", mobileCode: "65" },
  SK: { name: "Slovakia", mobileCode: "421" },
  SI: { name: "Slovenia", mobileCode: "386" },
  SB: { name: "Solomon Islands", mobileCode: "677" },
  SO: { name: "Somalia", mobileCode: "252" },
  ZA: { name: "South Africa", mobileCode: "27" },
  SS: { name: "South Sudan", mobileCode: "211" },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    mobileCode: "500",
  },
  ES: { name: "Spain", mobileCode: "34" },
  LK: { name: "Sri Lanka", mobileCode: "94" },
  SD: { name: "Sudan", mobileCode: "249" },
  SR: { name: "Suriname", mobileCode: "597" },
  SJ: { name: "Svalbard and Jan Mayen", mobileCode: "47" },
  SZ: { name: "Swaziland", mobileCode: "268" },
  SE: { name: "Sweden", mobileCode: "46" },
  CH: { name: "Switzerland", mobileCode: "41" },
  SY: { name: "Syrian Arab Republic", mobileCode: "963" },
  TW: { name: "Taiwan", mobileCode: "886" },
  TJ: { name: "Tajikistan", mobileCode: "992" },
  TZ: { name: "Tanzania, United Republic of Tanzania", mobileCode: "255" },
  TH: { name: "Thailand", mobileCode: "66" },
  TL: { name: "Timor-Leste", mobileCode: "670" },
  TG: { name: "Togo", mobileCode: "228" },
  TK: { name: "Tokelau", mobileCode: "690" },
  TO: { name: "Tonga", mobileCode: "676" },
  TT: { name: "Trinidad and Tobago", mobileCode: "1868" },
  TN: { name: "Tunisia", mobileCode: "216" },
  TR: { name: "Turkey", mobileCode: "90" },
  TM: { name: "Turkmenistan", mobileCode: "993" },
  TC: { name: "Turks and Caicos Islands", mobileCode: "1649" },
  TV: { name: "Tuvalu", mobileCode: "688" },
  UG: { name: "Uganda", mobileCode: "256" },
  UA: { name: "Ukraine", mobileCode: "380" },
  AE: { name: "United Arab Emirates", mobileCode: "971" },
  GB: { name: "United Kingdom", mobileCode: "44" },
  US: { name: "United States", mobileCode: "1" },
  UY: { name: "Uruguay", mobileCode: "598" },
  UZ: { name: "Uzbekistan", mobileCode: "998" },
  VU: { name: "Vanuatu", mobileCode: "678" },
  VE: {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    mobileCode: "58",
  },
  VN: { name: "Vietnam", mobileCode: "84" },
  VG: { name: "Virgin Islands, British", mobileCode: "1284" },
  VI: { name: "Virgin Islands, U.S.", mobileCode: "1340" },
  WF: { name: "Wallis and Futuna", mobileCode: "681" },
  YE: { name: "Yemen", mobileCode: "967" },
  ZM: { name: "Zambia", mobileCode: "260" },
  ZW: { name: "Zimbabwe", mobileCode: "263" },
};

const IP_STATIC_DATA = {
  city: "Noida",
  country_name: "India",
  country_code: "IN",
  ip: "14.194.154.234",
  connection: {
    asn: "AS45820",
    isp: "Tata Teleservices Limited -GSM Division",
  },
  currency: {},
  location: { calling_code: "91" },
  region_name: "Uttar Pradesh",
  time_zone: {},
  zip: "203207",
};

export const fetchIpInfoFun = async () => {
  let result = { ...IP_STATIC_DATA };
    let jsonData:any = {};
    try{
      jsonData = await fetchIpInfoApi();
    }catch(err){
      jsonData = {error: err};
    }
    console.log("jsonData:", jsonData);

    let headersJsonData:any = {};
    try{
      headersJsonData = await fetchHeadersCountryCode();
    }catch(err){
      headersJsonData = {error: err};
      console.error('headersJsonData::::::', headersJsonData)
    }

    if (!jsonData?.error) {
      const countryCode = headersJsonData?.countryCode || "IN";
      if (!jsonData?.country || jsonData?.country != countryCode) {
        console.log("IP country-code not match jsonData?.country, countryCode:", jsonData?.country, countryCode);
        let headersRes = setHeadersCountryCode(result, headersJsonData);
        result = { ...headersRes };
      } else {
        let countryDialObj =
          countryDialCodes[jsonData?.country] || countryDialCodes["IN"];

        result["city"] = jsonData?.city || IP_STATIC_DATA.city;
        result["country_name"] = countryDialObj.name;
        result["country_code"] =
          jsonData?.country || IP_STATIC_DATA.country_code;
        result["ip"] = jsonData?.ip || IP_STATIC_DATA.ip;
        result["connection"] = {
          asn: jsonData?.asn?.asn || IP_STATIC_DATA.connection.asn,
          isp: jsonData?.company?.name || IP_STATIC_DATA.connection.isp,
        };
        result["location"] = { calling_code: countryDialObj.mobileCode };
        result["region_name"] = jsonData?.region || IP_STATIC_DATA.region_name;
        result["zip"] = jsonData?.postal || IP_STATIC_DATA.zip;
      }
    } else {
      let headersRes = setHeadersCountryCode(result, headersJsonData);
      result = { ...headersRes };
    }

    return result;
}

async function fetchIpInfoApi(){
  try{
    const data = await fetch("https://ipinfo.io/json?token=6b10d7bf80f030");
    const jsonData = await data.json();
  
    return jsonData;
  }catch(e){
    return {error: e};
  }
}

async function fetchHeadersCountryCode(){
  try{
    const headersCountryCode = await fetch("/api/country-code");
    const headersJsonData = await headersCountryCode.json();

    return headersJsonData;
  }catch(e){
    return {error: e};
  }
}


function setHeadersCountryCode(result: any, headers: any) {
  const countryCode = headers?.countryCode || "IN";
  const countryName = headers?.countryName || "India";

  let countryDialObj = countryDialCodes[countryCode] || countryDialCodes["IN"];
  result["country_name"] = countryDialObj.name;
  result["country_code"] = countryCode;
  result["location"] = { calling_code: countryDialObj.mobileCode };

  return result;
}
